import React, {useContext, useEffect, useState} from 'react'
import {
    Box,
    Button,
    DialogActions,
    DialogContentText,
    Grid,
    Paper,
    ThemeProvider,
    Tooltip,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import MenuTile from '../../templates/MenuTile';
import {materialTable, useStyles, useTheme} from '../../css/Styles'
import {useTranslation} from 'react-i18next';
import MaterialTable from "@material-table/core";
import {FR, GB, PL} from 'country-flag-icons/react/3x2'
import {PagesEnums} from '../../utils/CustomEnums';
import {NavLink, useParams} from 'react-router-dom';
import {getCredentials} from '../../services/UserDataService';
import FetchService from '../../services/FetchService';
import PaymentComponent from './PaymentComponent';
import LinearProgress from '@mui/material/LinearProgress';
import {errorNotification, successNotification} from '../../utils/Notifications';
import logLevel from '../../utils/LoggerHelper';
import {checkArtisticCourse} from '../../utils/AppUtils';
import {DatesContext} from "../GlobalDatesContext";
import Checkbox from "@mui/material/Checkbox";
import Divider from '@mui/material/Divider';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export default function CourseSelection() {
    let {id} = useParams();
    const {t} = useTranslation();
    const theme = useTheme;
    const myMaterialTable = materialTable;
    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))
    const classes = useStyles();

    const checkedCourses = React.useRef([])

    const [studyDegree, setStudyDegree] = useState(null);
    const [dialogPopupOpen, setDialogPopupOpen] = useState(false);
    const [prioritizedCurseEventObject, setPrioritizedCurseEventObject] = useState({
        priorityValue: 0,
        courseID: 0,
        name: ""
    });
    const [recruitmentModeKey, setRecruitmentModeKey] = useState(id);
    const [tableData, setTableData] = React.useState([])
    const [selectedCourses, setSelectedCourses] = React.useState([])

    const [loadingCandidateCourses, setLoadingCandidateCourses] = React.useState(false)

    //accessContext
    const datesContext = useContext(DatesContext)

    // const dates = useContext(DatesContext)

    const [dates, setDates] = useState()
    //state for disabling edition
    const [readOnly, setReadOnly] = React.useState(true)

    const courseColumns = [{title: t('courseSelection.course'), field: "name", width: '80%'}, {
        title: t('courseSelection.recruitmentFee'),
        field: "recruitmentFee",
        width: '10%',
        type: 'currency',
        currencySetting: {locale: 'pl', currencyCode: 'PLN', minimumFractionDigits: 0, maximumFractionDigits: 2}
    }, {
        title: t('courseSelection.flag'), field: "flag", render: (rowdata) => (<>
            {rowdata.language === 'EN' ? <GB title="UK" style={{width: 50}}/> : rowdata.language === 'FR' ?
                <FR title="FR" style={{width: 50}}/> : <PL title="PL" style={{width: 50}}/>}
        </>),
    }, {title: t('courseSelection.faculty'), field: "facultyNameShortcut",}];

    async function fetchCourses(studyDegree, recruitmentModeKey) {
        return await FetchService.getCourses(studyDegree, recruitmentModeKey)
    }

    async function fetchCandidateCourses(studyDegree, recruitmentModeKey) {
        return await FetchService.getCandidateCourses(studyDegree, recruitmentModeKey)
    }

    async function fetchSaveCandidateCourses(courses, recruitmentModeKey) {
        return await FetchService.saveCandidateCourses(courses, recruitmentModeKey)
    }

    async function fetchGetRecruitmentMode(recruitmentModeKey) {
        return await FetchService.getRecruitmentMode(recruitmentModeKey)
    }

    async function fetchGetDates(trybSemester) {
        return await FetchService.getDates(trybSemester)
    }

    function getData() {
        Promise.all([fetchCourses(studyDegree, recruitmentModeKey), //todo: zmienić studyDegree na recruitmentModeKey
            fetchCandidateCourses(studyDegree, recruitmentModeKey)

        ]).then(([responseCourses, responseCandidateCourses]) => {
            setCandidateCourses(responseCourses.zawartosc, responseCandidateCourses.zawartosc);
            setLoadingCandidateCourses(false)
        }).catch(error => {
            logLevel.debug("Błąd podczas odczytu danych, Error " + error);
            errorNotification('default.errorOnGet', ' ');
            setLoadingCandidateCourses(false)
        })
    }

    function refreshSelectedCourses() {
        fetchCandidateCourses(studyDegree, recruitmentModeKey).then(response => {
            const courseTemp = response.zawartosc.map(singleCourse => {
                if (singleCourse.courseName === null || singleCourse.courseName === "undefined") {
                    const index = selectedCourses.indexOf(singleCourse);
                    if (index > -1) {
                        selectedCourses.splice(index, 1);
                    }
                }
            })
            setSelectedCourses(courseTemp);
            setLoadingCandidateCourses(false);
        })
    }

    useEffect(() => {
        setLoadingCandidateCourses(true)
        fetchGetRecruitmentMode(id).then(response => {
            if (response.zawartosc[0].recruitmentCandidateMode.key === id) {
                setStudyDegree(response.zawartosc[0].recruitmentCandidateMode.degree);
                if (!(response.zawartosc[0].candidateRecruitmentStatus.status === "APLIKACJA_WYSLANA" || response.zawartosc[0].candidateRecruitmentStatus.status === "APLIKACJA_KOMPLETNA")) {
                    datesContext.fetchGetMoreDates(recruitmentModeKey, datesContext.currentSemester).then(result => {
                        setDates(result)
                        manageAccess(result)
                    })
                }
            } else {
                setStudyDegree(0);
            }
        })



    }, [studyDegree, datesContext.currentSemester])

    function manageAccess(dates) {
        setReadOnly(true)
        if (new Date(dates[id]?.recruitmentStart) <= new Date(dates?.currentDateTime) && new Date(dates?.currentDateTime) <= new Date(dates[id]?.recruitmentClosure)) {
            setReadOnly(false)
        }
    }

    useEffect(() => {
        if (studyDegree) {
            getData();
        }
    }, [studyDegree])

    function setCandidateCourses(courses, candidateCourses) {
        let tempData = [...courses];

        candidateCourses.forEach(element => {
            let index = tempData.map(e => e.id).indexOf(element.courseId);
            // selectedCoursesTemp.push(...tempData.splice(index, 1));
            tempData.splice(index, 1)
        })
        setTableData(tempData)
        setSelectedCourses(candidateCourses);
    }

    function addCoursesHandler() {
        let tempData = [...tableData]
        if (checkedCourses.current.length === 0) {
            alert(t('courseSelection.pickOneCourse'));
        } else if ((selectedCourses.length + checkedCourses.current.length) <= 4) {
            checkedCourses.current.forEach(element => {
                let index = tempData.map(e => e.id).indexOf(element.id);
                tempData.splice(index, 1)
            })
            setTableData(tempData)

            let selectedCoursesAll = selectedCourses.concat(checkedCourses.current.map(checkedCourse => {
                return {courseId: checkedCourse.id}
            }))
            setSelectedCourses(selectedCoursesAll)
            saveButtonHandler(selectedCoursesAll)
            checkedCourses.current = []

        } else {
            alert(t('courseSelection.tooManyCoursesAlert'));
        }

    }

    function saveButtonHandler(courses) {
        setLoadingCandidateCourses(true);
        let coursesIds = courses.map((val) => {
            return val.courseId;
        })
        fetchSaveCandidateCourses(coursesIds, recruitmentModeKey).then(() => {
            getData()
            //refreshSelectedCourses();
        })
    }


    const handleDeleteSelectedCourse = (element) => {
        let tempSelectedCourses = [...selectedCourses]
        //usunięcie wybranego kierunku
        tempSelectedCourses.forEach(e => {
            if (e.id === element.id) {
                let index = tempSelectedCourses.map(e => e.id).indexOf(element.id);
                tempSelectedCourses.splice(index, 1)
                setSelectedCourses(tempSelectedCourses)
                saveButtonHandler(tempSelectedCourses)
            }
        })
    }

    /**
     * Dto do zapisu priorytetu wybranych kierunków na dany etap rekrutacji.
     *
     * id - Id rekordu w wwydzialkierunek
     * priority - priorytet 0 - nie, 1 - tak
     * recruitmentMode - Tryb rekrutacji.
     * profileId - Profil kandydata
     */
    const handleChangeInCheckbox = (event, courseID) => {
        let course = selectedCourses.find(course => course.id === courseID);
        if (course.priority != 1) {
            let priorityValue = (event.target.checked ? 1 : 0)
            setDialogPopupOpen(true);

            setPrioritizedCurseEventObject({
                priorityValue: priorityValue,
                courseID: courseID,
                name: (course ? course.courseName : "")
            })
        }
    }

    const handleChangeOfPrioritizedCourses = () => {
        let tmpCourses = JSON.parse(JSON.stringify(selectedCourses));
        if (!prioritizedCurseEventObject.priorityValue) {
            FetchService.savePriority({
                priority: prioritizedCurseEventObject.priorityValue,
                id: prioritizedCurseEventObject.courseID,
                recruitmentMode: recruitmentModeKey
            }).then((response) => {
                if (response && response?.status == "OK") {
                    let index = tmpCourses.findIndex(course => course.id == prioritizedCurseEventObject.courseID);
                    tmpCourses.at(index).priority = prioritizedCurseEventObject.priorityValue;

                    successNotification(response.message)
                    setSelectedCourses(tmpCourses)
                }
            })

        } else {
            let index = tmpCourses.findIndex(course => course.priority == 1);
            if (index != -1) {
                FetchService.savePriority({
                    priority: 0, id: tmpCourses[index].id, recruitmentMode: recruitmentModeKey
                }).then((response) => {
                    if (response && response?.status == "OK") {

                        tmpCourses.at(index).priority = 0;

                        FetchService.savePriority({
                            priority: prioritizedCurseEventObject.priorityValue,
                            id: prioritizedCurseEventObject.courseID,
                            recruitmentMode: recruitmentModeKey
                        }).then((response) => {
                            if (response && response?.status == "OK") {

                                index = tmpCourses.findIndex(course => course.id == prioritizedCurseEventObject.courseID);

                                tmpCourses.at(index).priority = 1;


                                successNotification(response.message)
                                setSelectedCourses(tmpCourses)
                            }


                        })


                    }

                })
            } else {
                FetchService.savePriority({
                    priority: prioritizedCurseEventObject.priorityValue,
                    id: prioritizedCurseEventObject.courseID,
                    recruitmentMode: recruitmentModeKey
                }).then((response) => {
                    if (response && response?.status == "OK") {
                        index = tmpCourses.findIndex(course => course.id == prioritizedCurseEventObject.courseID);

                        tmpCourses.at(index).priority = 1;

                        successNotification(response.message)
                        setSelectedCourses(tmpCourses)
                    }
                })
            }

        }
    }


    return (
        <>
            <ThemeProvider theme={theme}>
        <Box sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: isMobile ? 'center' : 'flex-start',
            flexDirection: isMobile ? 'column' : 'row'
        }}>
            <Grid sx={{padding: '2.5vh'}} display="flex" justifyItems="end">
                <MenuTile title={t('main.courseSelection')} locked={false}/>
            </Grid>
            {/* Padding grida poniżej jest zawsze o 1vh większy, bo padding komponentu MenuTile to 1vh */}
            <Grid sx={{padding: isMobile ? '1vh' : '3vh', width: '100%', height: '100%'}} container
                  direction={'row'} justifyContent="space-between" alignItems="space-between">
                <Grid sx={{width: '100%'}}>
                    <ThemeProvider theme={myMaterialTable}>

                        <MaterialTable
                            title={t('courseSelection.chooseCourse')}
                            style={{width: '100%'}}
                            data={tableData}
                            columns={courseColumns}
                            onSelectionChange={rows => checkedCourses.current = rows}
                            options={{
                                search: true,
                                selection: true,
                                showSelectAllCheckbox: false,
                                paging: false,
                                maxBodyHeight: '30vh',
                                toolbar: true,
                                selectionProps: rowData => ({
                                    disabled: readOnly || checkArtisticCourse(rowData.id, dates, id),
                                })
                            }}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: t('noSearchResult')
                                }, toolbar: {
                                    searchPlaceholder: t('courseSelection.search'),
                                    searchTooltip: t('courseSelection.search')
                                }
                            }}
                        />
                    </ThemeProvider>

                    <Grid container style={{width: '100%', marginTop: '1vh'}} justifyContent={"flex-end"}>
                        <Button
                            sx={{
                                fontWeight: 'bold',
                                marginLeft: '1vw',
                                color: theme.palette.primary.main,
                                backgroundColor: theme.palette.secondary.main,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.hovered,
                                    borderColor: 'none',
                                    boxShadow: 'none',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: theme.palette.additional.disabled,
                                }
                            }}
                            disabled={readOnly}
                            variant='contained'
                            onClick={addCoursesHandler}
                        >
                            {t('courseSelection.addCourseButton')}
                        </Button>
                    </Grid>


                    <Grid container style={{width: '100%', color: '#ff0000', paddingTop: '3vh'}} spacing={3}
                          direction={'row'}>
                        <Grid item xs={1} style={{width: '10%', ppaddingBottom: '1.5vh', paddingTop: '1.5vh'}}>
                            <PriorityHighIcon fontSize="large" color="inherit"/>
                        </Grid>
                        <Grid item xs={4} style={{width: '80%', paddingBottom: '1.5vh', paddingTop: '1.5vh'}}>
                            <Typography variant="h6" color="inherit">
                                {t('courseSelection.prioritySelectionInfo')}
                            </Typography>
                            <Typography variant="h6" color="inherit">
                                {t('courseSelection.prioritySelectionInfo2')}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} style={{width: '10%', paddingBottom: '1.5vh', paddingTop: '1.5vh'}}>
                            <PriorityHighIcon fontSize="large" color="inherit"/>
                        </Grid>
                    </Grid>


                    <ThemeProvider theme={myMaterialTable}>

                        <Paper elevation={2}>

                            {loadingCandidateCourses ?
                                <Grid container style={{width: '100%'}} alignItems='center' justifyContent='center'>
                                    <LinearProgress sx={{width: '80%'}} color="secondary"/>
                                </Grid> : <>
                                    <Grid style={{marginBottom: '1.5vh', marginTop: '1.5vh', paddingTop: '1.5vh'}}
                                          container direction={'row'}
                                          justifyContent='space-between' alignItems='center'>


                                        <Grid style={{width: '10%'}} item>
                                            <Typography variant='h6' color={theme.palette.secondary.main}
                                                        style={{textAlign: 'center'}}>
                                                {t('courseSelection.priority')}
                                            </Typography>
                                        </Grid>

                                        <Grid style={{width: '35%'}} item>

                                            <Typography variant='h6' color={theme.palette.secondary.main}
                                                        style={{textAlign: 'center'}}>
                                                {t('courseSelection.chosenDegrees')}
                                            </Typography>

                                        </Grid>

                                        <Grid style={{width: '50%'}} item>

                                            <Typography variant='h6' color={theme.palette.secondary.main}
                                                        style={{textAlign: 'center'}}>
                                                {t('courseSelection.payment')}
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                    <Divider/>

                                    <Grid container direction='column' style={{width: '100%'}}>

                                        {selectedCourses.sort((a, b) => a.courseName.localeCompare(b.courseName)).map((element) => {
                                            return (element && <>
                                                    <Grid key={element.id} style={{
                                                        paddingBottom: '1.5vh',
                                                        paddingTop: '1.5vh',
                                                        backgroundColor: (element.priority == 1 ? "#bbdefb" : "#ffffff")
                                                    }} container direction={'row'}
                                                          justifyContent='space-between' alignItems='center'>

                                                        <Grid item style={{width: '10%'}} alignItems='center'>
                                                            {element.recruitment.status != 'PAYMENT_END' ? <Tooltip
                                                                title={t('courseSelection.priorityTooltip')}
                                                            >
                                                                <span>
                                                                    <Checkbox
                                                                        icon={<RadioButtonUncheckedIcon/>}
                                                                        checkedIcon={<RadioButtonCheckedIcon/>}

                                                                        disabled={true}
                                                                        onChange={(e) => handleChangeInCheckbox(e, element.id)}
                                                                        checked={element.priority}
                                                                    /> 
                                                                </span>
                                                            </Tooltip> : <Checkbox
                                                                icon={<RadioButtonUncheckedIcon/>}
                                                                checkedIcon={<RadioButtonCheckedIcon/>}
                                                                disabled={readOnly}
                                                                onChange={(e) => handleChangeInCheckbox(e, element.id)}
                                                                checked={element.priority}
                                                            />}
                                                        </Grid>

                                                        <Box sx={{
                                                            width: isMobile ? '90%' : '35%',
                                                            backgroundColor: (element.priority == 1 ? "#bbdefb" : "#ffffff")
                                                        }}
                                                             className={classes.courseDisplayPaper}>

                                                            {element.language === 'EN' ? <GB title="UK"
                                                                                             style={{
                                                                                                 maxHeight: '2vh',
                                                                                                 marginRight: '1vw'
                                                                                             }}/> : element.language === 'FR' ?
                                                                <FR title="FR"
                                                                    style={{
                                                                        maxHeight: '2vh', marginRight: '1vw'
                                                                    }}/> : <PL title="PL"
                                                                               style={{
                                                                                   maxHeight: '2vh',
                                                                                   marginRight: '1vw'
                                                                               }}/>}

                                                            <Typography variant='h6'
                                                                        color={theme.palette.secondary.main}
                                                                        style={{textAlign: 'left'}}>
                                                                {element.courseName} ({element.recruitment.recruitmentFee} zł.)
                                                            </Typography>
                                                        </Box>
                                                        <PaymentComponent dates={dates} course={element}
                                                                          index={element.id}
                                                                          recruitmentModeKey={recruitmentModeKey}
                                                                          handleDeleteSelectedCourse={handleDeleteSelectedCourse}
                                                                          handleGetCandidateCourses={getData}
                                                                          disabled={readOnly}
                                                        />
                                                    </Grid>
                                                    <Divider/>
                                                </>

                                            )

                                        })}

                                    </Grid>
                                </>}
                        </Paper>

                    </ThemeProvider>

                </Grid>
                <Grid container style={{width: '100%'}} justifyContent={isMobile ? 'center' : "flex-end"}
                      alignItems='flex-end'>
                    <Button
                        sx={{
                            fontWeight: 'bold',
                            marginLeft: 1,
                            marginTop: 1,
                            marginBottom: 1,
                            height: '3vh',
                            minHeight: '40px',
                            color: theme.palette.primary.main,
                            backgroundColor: theme.palette.secondary.main,
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.hovered,
                                borderColor: 'none',
                                boxShadow: 'none',
                            },
                        }}
                        component={NavLink}
                        to={getCredentials() ? PagesEnums.MAIN.link : PagesEnums.HOME.link}
                        variant='contained'>
                        {t('backButton')}
                    </Button>
                </Grid>
            </Grid>
        </Box>

            </ThemeProvider>
            <Dialog onClose={() => setDialogPopupOpen(false)} open={dialogPopupOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle> {t('courseSelection.changePriorityDialogTitle')}</DialogTitle>
                <DialogContentText id="alert-dialog-slide-description">
                    <Grid item style={{
                        paddingRight: '1.5vh',
                        paddingLeft: '1.5vh',
                    }}>
                        <Typography>
                            {t('courseSelection.changePriorityDialogMessage')}
                        </Typography>
                        <Typography>
                            {prioritizedCurseEventObject.name}
                        </Typography>
                    </Grid>

                </DialogContentText>
                <DialogActions>
                    <Button onClick={() => setDialogPopupOpen(false)}>
                        {t("no")}
                    </Button>
                    <Button onClick={() => {
                        setDialogPopupOpen(false)
                        handleChangeOfPrioritizedCourses()
                    }} autoFocus>
                        {t("yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}