import * as React from "react";
import { useTranslation } from "react-i18next";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';


export default function CustomExamResultsTable({ tableData }) {

  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="blue-header">
          <TableRow>
            <TableCell align="left">{t('candidateExams.name')}</TableCell>
            <TableCell align="center">{t('candidateExams.presence')}</TableCell>
            <TableCell align="center">{t('candidateExams.points')}</TableCell>
            <TableCell align="center">{t('candidateExams.result')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => {
            console.log(row)
            return (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.name}</TableCell>
                {row.finalGrade === '1111' ?
                  <TableCell
                    align="center">-</TableCell> :
                  <TableCell
                    align="center">{row.presentOnExam === true ? t('candidateExams.present') : row.presentOnExam === false ? t('candidateExams.notPresent') : t('candidateExams.noData')}</TableCell>}
                {row.finalGrade === '1111' ?
                  <TableCell align="center">-</TableCell> :
                  <TableCell align="center">{t(row.finalGrade)}</TableCell> }
                {row.finalGrade === '1111' ?
                  <TableCell
                    align="center">{row.passed === true ? t('candidateExams.accepted') : row.passed === false ? t('candidateExams.notAccepted') : t('candidateExams.noData')}</TableCell> :
                  <TableCell
                    align="center">{row.passed === true ? t('candidateExams.passed') : row.passed === false ? t('candidateExams.notPassed') : t('candidateExams.noData')}</TableCell>}
              </TableRow>
            )
          }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}