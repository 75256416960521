import * as React from "react";
import { useTranslation } from "react-i18next";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';

export default function CustomCourseConditionsTable({ tableData }) {

    const { t } = useTranslation();

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="blue-header">
                    <TableRow>
                        <TableCell>{t('courseRequirements.name')}</TableCell>
                        <TableCell align="center">{t('courseRequirements.docs')}</TableCell>
                        <TableCell align="center">{t('courseRequirements.title')}</TableCell>
                        <TableCell align="center">{t('courseRequirements.student')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((row, index) => {
                        return (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{row.name}</TableCell>
                                <TableCell
                                    align="center">{row.docs === true ? <img src="/check.png" alt="Spełnia" width="30" /> : row.docs === false ? <img src="/cross.png" alt="Nie spełnia" width="40" /> : <img src="/cross.png" alt="Nie spełnia" width="40" />}</TableCell>
                                <TableCell align="center">{row.titles === true ? <img src="/check.png" alt="Spełnia" width="30" /> : row.titles === false ? <img src="/cross.png" alt="Nie spełnia" width="40" /> : <img src="/cross.png" alt="Nie spełnia" width="40" />}</TableCell>
                                <TableCell align="center">{row.studentStatus === true ? <img src="/check.png" alt="Spełnia" width="30" /> : row.studentStatus === false ? <img src="/cross.png" alt="Nie spełnia" width="40" /> : <img src="/cross.png" alt="Nie spełnia" width="40" />}</TableCell>
                            </TableRow>
                        )
                    }
                    )}                   
                </TableBody>
            </Table>
        </TableContainer>
    )

}