import React, {useRef} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material';
import {useStyles, useTheme} from "../../css/Styles";
import LinearWithValueLabel from "./LinearProgressWithLabel";
import TodoTask from "./TodoTask";
import {t} from "i18next";
import Grid from "@mui/material/Grid";
import {Dialog, ThemeProvider, useMediaQuery} from "@material-ui/core";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import FetchService from "../../services/FetchService";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {successNotification} from "../../utils/Notifications";

export default function TodoStage({stageData, reloadPage, expanded, handleIsModeActiveChange}) {
    const theme = useTheme;
    const isMobile = useMediaQuery(theme.breakpoints.down('phone'))
    const buttonRef = useRef(null);
    const classes = useStyles();
    const handleAccordionChange = (event) => {
        if (buttonRef.current && buttonRef.current.contains(event.target)) {
            // Click came from the button, so don't toggle the accordion
            return;
        }
        // Proceed with changing the state
        handleIsModeActiveChange(stageData.tryb);
    };
    const [open, setOpen] = React.useState(false);


    return (
        <ThemeProvider theme={theme}>
            <Accordion defaultExpanded={stageData.isActiveMode} onChange={handleAccordionChange}>
            <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Grid container direction={isMobile ? 'column' : 'row'} alignItems='center'
                      justifyContent="space-evenly" sx={{width: '100%'}}>
                    <Grid item sx={{width: isMobile ? '100%' : '40%'}}>
                        <Grid container sx={{width: '100%'}} direction='column' spacing={isMobile ? 2 : 3.5}>
                            <Grid item>
                                <Typography sx={{fontWeight: 'bold'}}>
                                    {t('main.degree.' + stageData.tryb)}
                                </Typography>
                            </Grid>
                            <Grid item sx={{marginBottom: isMobile ? '0' : '-10px'}}>
                                <LinearWithValueLabel current={stageData.current} max={stageData.max}
                                                      progress={stageData.progress}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={{width: isMobile ? '100%' : '40%'}}>
                        <Grid container sx={{width: '100%'}} direction='column' spacing={2}>
                            <Grid item>
                                <Chip
                                    sx={{
                                        height: 'auto',
                                        '& .MuiChip-label': {
                                            display: 'block',
                                            whiteSpace: 'normal',
                                        },
                                    }}
                                    className={classes.todoChip}
                                    label={stageData.status === "BRAK" ? stageData.progress === 100 ? t("todo.ready") : t("todo.notYet") : t('todoList.' + stageData.status)}
                                    color={stageData.status === "BRAK" ? stageData.progress === 100 ? "warning" : "info" : stageData.status === "APLIKACJA_NIEKOMPLETNA" ? "error" : stageData.status === "APLIKACJA_KOMPLETNA" ? "success" : "info"}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item sx={{width: '100%', height: isMobile ? '50px' : '50px'}}>
                                {stageData.isActiveMode ?
                                    <Button
                                    ref={buttonRef}
                                    disabled={stageData.progress !== 100 || stageData.status === "APLIKACJA_WYSLANA" || stageData.status === "APLIKACJA_KOMPLETNA"}
                                    sx={{
                                        fontWeight: 'bold',
                                        margin: 0,
                                        color: theme.palette.primary.main,
                                        backgroundColor: theme.palette.secondary.main,
                                        '&:hover':
                                            {
                                                backgroundColor: theme.palette.secondary.hovered,
                                                borderColor: 'none', boxShadow: 'none',
                                            },
                                        '&.Mui-disabled': {
                                            backgroundColor: theme.palette.additional.disabled,
                                        }
                                    }}
                                    variant='contained'
                                    onClick={() => setOpen(true)}
                                >
                                        {(t('main.todoTitle')).toUpperCase()}
                                    </Button> : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionSummary>
                <AccordionDetails sx={{backgroundColor: '#f2f2f2'}}>
                    <Grid container direction='column' spacing={'10px'}>
                        {stageData.toDoList?.map(task => (
                            <TodoTask taskData={task} key={task.order}/>
                        ))}
                </Grid>
            </AccordionDetails>
        </Accordion>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{(t("todoList.sendWarningTitle"))}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {(t("todoList.sendWarning"))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        {t('todoList.cancel')}
                    </Button>
                    <Button type="submit" onClick={() => FetchService.putRecruitmentStatus({
                        recruitmentModeKey: stageData.tryb,
                        recruitmentStatus: "APLIKACJA_WYSLANA"
                    }).then(response => {
                        if (response && response.zawartosc === true) {
                            successNotification("todoList.successNotification", "");
                            reloadPage()
                            setOpen(false)
                        }
                    })} color="secondary">
                        {t('todoList.send')}
                    </Button>
                </DialogActions>
            </Dialog>

        </ThemeProvider>
    );
}